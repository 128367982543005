.custom-data-table img {
  max-width: 100%;
}

.custom-data-table .context-menu {
  display: flex;
  justify-content: space-between;
  margin: 12px 0 36px;
}

.custom-data-table .context-menu-actions {
  padding: 10px 0 0;
}

.custom-data-table .context-menu .custom-icon-label-button {
  font-weight: 600;
  padding: 0;
  margin-right: 24px;
}

.custom-data-table .data-table-skeleton {
  margin-bottom: 16px;
  background-color: #f6f8fd;
  background-image: linear-gradient(90deg, #f6f8fd, #fafbff, #f6f8fd);
}

.custom-data-table div[role="columnheader"] span {
  width: inherit;
}

.custom-data-table span {
  width: 100%;
}

.sc-fzpans {
  width: auto !important;
}

.custom-data-table input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #cacece;
  padding: 6px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  flex: unset;
  margin-top: 0 !important;
}

.custom-data-table input[type="checkbox"]:after {
  content: "";
}

.custom-data-table input[type="checkbox"]:checked::after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: #1e4c88;
  border-radius: 2px;
}

.jKckZB:hover {
  background-color: #f6f8fd !important;
}

.ezmTUU div:first-child,
.iMkHZl div:first-child,
.rdt_TableCell div:first-child {
  /* overflow: unset !important; */
  word-break: break-word;
}

.rdt_TableCell .dropdown_wrapper div:first-child {
  /* overflow: hidden !important; */
  word-break: break-word;
}

.custom-data-table .rdt_TableRow button:hover:not(:disabled) {
  background-color: transparent !important;
}

.custom-data-table .highlight-search-input {
  border-color: #5000bf !important;
}

.custom-data-table .rdt_Table {
  min-height: 250px;
}