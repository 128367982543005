.merchant-offer-page {
    padding: 40px;
}

.merchant-offer-container {
    text-align: right;
    padding-left: 20px;
    padding-top: 20px;
}

.merchant-offer-button {
    height: 38px;
    width: 100px;
    background: #34d0b6;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.blue-button {
    height: 38px;
    width: 100px;
    background: #0dcaf0;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}

.red-button {
    height: 38px;
    width: 100px;
    background: #f36565;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}