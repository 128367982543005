.index-page {
    padding: 40px;
}

.index-container {
    text-align: right;
    padding-left: 20px;
    padding-top: 20px;
}

.dobin-primary {
    background-color: #34D0B6 !important;
}

.index-button {
    height: 38px;
    width: 100px;
    background: #34d0b6;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.grey-button {
    height: 38px;
    width: 100px;
    background: #b5bebe;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
