
body, * {
    font-family: 'Inter';
}
body, html {
    overflow-x: hidden;
}

.bg-image-dobin {
    background-image: url(https://lender.dobin.io/static/img/bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: none;
}
.logo {
    padding: 80px 0 62px;
}
.main-logo {
    width: 140px;
    height: 40px;
}
.signup .title h1 {
    
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #FFFFFF;
    margin-bottom: 32px;
}
#signup .signup-form {
    margin-bottom: 50px;
}
.signup .description {
    padding-bottom: 180px;
}
label.error {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #D7453C !important;
}
.error {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #D7453C !important;
}

label#email-error, label#mobile_numbeßr-error, #login_email-error, #login_password-error {
    padding-top: 8px;
}
.signup-form .input-group-text {
    position: absolute;
    right: -1px;
    height: 52px;
    z-index: 54;
}
.signup-form .border-right-radius {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}
#password-error, #confirmPassword-error, #newpassword-error, #confirmnewPassword-error, label#confirm_password-error {
    flex-basis: 100%;
    padding-top: 8px;
}
.pl-80 {
    padding-left: 80px;
    padding-right: 78px;
}
button.btn:focus {
    box-shadow: none;
}
.btn:active,
.btn:focus,
.btn:focus:active {
  outline: 0;
  box-shadow: none;
}

.border-right {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}
.logo span {
    width: 2px;
    height: 20px;
    background: #fff;
    margin-left: 18px;
}
.signup .form-check-input[type=checkbox] {
    border-radius: 100%;
}
.signup #pills-tab li {
    padding: 4px;
}
.signup #pills-tab li button {
    
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #34D0B6;
}
.signup #pills-tab {
    border: 1px solid #34D0B6;
    border-radius: 42px;
    width: 216px;
}
.signup #pills-tab li button.active {
    width: 100px;
    height: 44px;
    background: #34D0B6 !important;
    border-radius: 38px;
    
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FAFDFF;
}
.signup .title h2 {
    
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
    color: #7E7E7E;
}
.mt-44 {
    margin-top: 44px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-28 {
    margin-top: 28px;
}
button.btn-primary {
    background: #34D0B6;
    border-color: #34D0B6;
}
button.btn {
    width: 100%;
    border-radius: 4px;
    
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FAFDFF;
    height: 52px;
}
.signup .btn-primary {
    border-radius: 8px;
}
button.btn-primary:hover {
    background: #34D0B6;
    border-color: #34D0B6;
}



.form-check-input:checked {
    background: #34D0B6;
    border-color: #34D0B6;
}
.signup-form .row input, .form-select {
    
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #3F3F3F !important;
    height: 52px;
    border: 1px solid #B1B1B1 !important;
    border-radius: 8px;
}

.border-right-none {
    border-right: none !important;
}
form.signup-form i {
    color: #7E7E7E;
}
.description {
    width: 454px;
}
.form-check.privacy .form-check-input {
    width: 20px;
    height: 20px;
}
.bg-lines {
    position: relative;
}

button:disabled, button[disabled]{    
    background-color: gray !important;
    border-color: gray !important;
}

/*****************Sign-in ***********************/
.signin_title:after {
    content: '';
    background: #20B996;
    display: block;
    width: 371px;
    height: 68px;
    position: absolute;
    top: 0;
    z-index: -1;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}
.title.signin_title {
    position: relative;
    z-index: 54;
}
.title.signin_title h1 {
    margin-bottom: 0;
    height: 68px;
    display: flex;
    align-items: center;
    padding-left: 39px;
}
.pt-73 {
    padding-top: 73px;
}
.border-radius-100 {
    border-radius: 100% !important;
}
.form-check-input:focus {
    box-shadow: none;
}

/**********Media Query*********/

@media (min-width: 1500px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1440px;
    }
 
}
@media (max-width: 1700px) {
.w-600 {
    width: 612px;
}
/* .signup .vh-100 {
    height: 100% !important;
} */
.col-lg-8.w-500 {
    width: 508px;
}
.w-800 {
    width: 828px;
}
}
@media screen and (max-width: 1430px) {
body .w-800 {
    width: auto;
    margin: 0 auto;
}
}
@media screen and (max-width: 1367px) {

}
@media screen and (min-width: 1300px) {
.overflow-x-hidden {
    overflow-x: hidden !important;
}
}
