.app-navbar-container {
    width: 100%;
    background-color: #34D0B6;
    padding-left: 30px;
    padding-right: 30px;
}

.app-navbar {
    background-color: #34D0B6;
    color: #000000;
}
